import * as io from 'socket.io-client';
import * as $ from "jquery";

//import custom
import config from '../config'

export default class IoService {

  socket;
  rooms = {};
  isConnected = false;
  onReSubscribe = [];

  constructor() { }

  connect(uuid) {
    this.disconnect();
    this.isConnected = true;
    const rooms = this.rooms = {};
    rooms[uuid] = true;

    const socket = this.socket = io(config.baseAPI, {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: 10000,
      autoConnect: false
    });

    socket.on('connect', this.initialSubscribe);
    socket.on('disconnect', this.setIsConnectedOff);
    socket.on('connect_error', this.setIsConnectedOff);
    socket.on('connect_timeout', this.setIsConnectedOff);
    socket.on('reconnect', this.resubscribe);

    socket.open();
  }

  disconnect() {
    if (this.socket) {
      this.isConnected = false;
      const socket = this.socket;
      Object.keys(this.rooms)
        .forEach(k=>this.socket.emit('unsubscribe', k));
      socket.off('connect', this.initialSubscribe);
      socket.off('disconnect', this.setIsConnectedOff);
      socket.off('connect_error', this.setIsConnectedOff);
      socket.off('connect_timeout', this.setIsConnectedOff);
      socket.off('reconnect', this.resubscribe);
      socket.close();
    }
  }

  setIsConnectedOff = (msg) => { this.isConnected = false; }

  initialSubscribe = () => {
    this.isConnected = true;
    Object.keys(this.rooms)
      .forEach(k=>this.socket.emit('subscribe', k));
  }

  resubscribe = () => {
    this.isConnected = true;
    Object.keys(this.rooms)
      .forEach(k=>this.socket.emit('subscribe', k));
    if (Array.isArray(this.onReSubscribe)) {
      this.onReSubscribe
        .filter(x=>$.isFunction(x))
        .forEach(fn=>fn());
    }
  }

  subscribe = room => {
    if (!this.rooms[room]) {
      this.socket.emit('subscribe', room);
      this.rooms[room] = true;
    }
  }

  unsubscribe = room => {
    if (this.rooms[room]) {
      this.socket.emit('unsubscribe', room);
      delete this.rooms[room];
    }
  }

  off = (...args) => {
    return this.socket.off(...args);
  }

  on = (...args) => {
    return this.socket.on(...args);
  }
}
