import InboxService from '../../services/inbox';
import GlobalService from '../../services/global';
import IoService from '../../services/io';

import Loading from '../../components/loading/loading.vue';

import moment from 'moment';

export default {
  name: 'Inbox',
  title: 'Inbox',

  methods: {
    fetchFriends(force = false) {
      if (!force) this.loading = true;
      return InboxService
        .getFriendRequests(this.$store.state.uuid)
        .then(r => {

          const frs = r.data;

          this.items = frs['frs'].filter(x => (x.type === 'personal' && x.status === 'accepted') || x.type === 'experience');
          this.items.forEach(item => {
            if (item.amISender) item.user = item.sentTo;
            else item.user = item.sentBy;
          });
          this.allItems = this.items;
          this.pendingOnMeFrs = frs['tpom'];

          if (this.items.length) {
            this.activeItem = this.items[0];
            this.activeIndex = this.active = this.items.findIndex(x => x.user._id == this.activeItem.user._id);
            this.fetchFriendChats(0, force);
          }

          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },

    fetchRequests() {
      this.loading = true;
      return InboxService
        .getRequests(this.$store.state.uuid)
        .then(r => {
          const res = r.data;

          this.items = res['requests'];
          this.allItems = this.items;
          this.pendingOnMe = res['tpom'];

          if (this.items.length) {
            this.activeItem = this.items[0];
          }
          
          this.loading = false;
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        })
    },

    fetchFriendChats(index, force = false) {
      if (!force && ((index === undefined) || (this.items[index].user._id === this.activeItem.user._id && this.chats.length))) return;

      if (!force) this.isFetching = true;
      const uuid = this.items[index].user._id;
      InboxService
        .getFriendChats(uuid)
        .then(r => {
          const res = r.data;
          this.chats = res['chats'];
          this.rooms = res['rooms'];

          this.checkIfOnline(uuid);
         
          this.activeItem = this.items[index];
          this.isFetching = false;

          setTimeout(() => {
            const container = this.$el.getElementsByClassName("msgs")[0];
            if (container) container.scrollTop = container.scrollHeight;
          }, 100);
        })
        .catch(onerror => {
          console.log(onerror);
          this.isFetching = false;
        });
    },

    getPhoto(data) {
      return GlobalService.getUserPhoto(data);
    },

    myPhoto() {
      return GlobalService.getUserPhoto(this.$store.state.userInfo.photos);
    },

    checkIfOnline(uuid) {
      if (this.rooms.includes(uuid)) this.isOnline = true;
      else this.isOnline = false;
    },

    switchTo() {
      if (this.activeTab === 'trips') {
        this.fetchFriends();
        this.activeTab = 'friends'; 
      } else {
        this.fetchRequests();
        this.activeTab = 'trips';
      }
    },

    getAgo(time) {
      return moment(time).fromNow();
    },

    sendMessage() {
      this.chats.push({
        message: this.message,
        showLoading: true,
        isMyMessage: true,
        createdAtAgo: 'just now'
      });

      setTimeout(() => {
        const container = this.$el.getElementsByClassName("msgs")[0];
        if (container) container.scrollTop = container.scrollHeight;
      }, 100);

      //push message to the server
      const ro = { message: this.message }
      this.message = '';

      return InboxService
        .sendMessage(ro, this.activeItem.user._id)
        .then(r => this.fetchFriends(true));
    } 
  },

  watch: {
    active(newValue) {
      this.fetchFriendChats(newValue)
    }
  },

  mounted() {
    if (!this.io.isConnected) {
      this.io.connect(this.$store.state.uuid);

      const newMessasgeHandler = () => { 
        this.fetchFriendChats(this.activeIndex, true);
      }

      this.io.on('new-message', newMessasgeHandler);
      this.io.on('new-personal-message', newMessasgeHandler);
      this.io.on('new-experience-message', newMessasgeHandler);
      this.io.on('all-read', newMessasgeHandler);
    }

    this.loading = true;
    this.fetchFriends();
  },

  components: {
    Loading
  },

  data: () => {
    return {
      io: new IoService(),
      loading: false,
      isFetching: false,
      active: 0,
      activeItem: {},
      message: '',
      pendingOnMe: 0,
      pendingOnMeFrs: 0,
      activeTab: 'friends',

      chats: [],
      rooms: [],

      isOnline: false,

      items: [],
      allItems: []
    }
  }
}