import Vue from 'vue';

export default {
  getRequests(_id, status = 'all', noProgress = true) {
    return Vue.axios.get(`r/${_id}/${status}`, {noProgress: noProgress});
  },

  getFriendRequests(_id, noProgress = true) {
    return Vue.axios.get(`u/fr/${_id}`, {noProgress: noProgress});
  },

  getRequestChats(_id, noProgress = true) {
    return Vue.axios.get(`r/chat/${_id}`, {noProgress: noProgress})
  },

  getFriendChats(_id, noProgress = true) {
    return Vue.axios.get(`u/chat/${_id}`, {noProgress: noProgress})
  },

  sendMessage(ro, _to, noProgress = true) {
    return Vue.axios.post(`u/chat/${_to}`, ro, {noProgress: noProgress});
  }
}
